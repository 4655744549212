import { AppBar, Box, Hidden, IconButton, Link, makeStyles, Toolbar, useTheme, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Logo from 'src/components/Logo';
import { ROOT_APP } from 'src/routes/paths';
import { THEMES } from 'src/constants';
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    zIndex: theme.zIndex.topBar,
    padding: theme.spacing(0, 2),
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconButton: {
    color: theme.palette.common.white,
  },
}));

const TopBar = ({ onMobileNavOpen }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { settings, saveSettings } = useSettings();

  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton className={classes.iconButton} onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Logo />
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box mr={2}>
          <Tooltip title={settings.theme === THEMES.LIGHT ? 'Change to dark mode' : 'Change to light mode'}>
            <IconButton
              onClick={() => {
                saveSettings({ theme: settings.theme === THEMES.LIGHT ? THEMES.ONE_DARK : THEMES.LIGHT });
              }}
            >
              <Iconify
                icon={
                  settings.theme === THEMES.LIGHT
                    ? 'material-symbols:dark-mode-outline'
                    : 'material-symbols:light-mode-outline'
                }
                width={20}
                height={20}
                color={theme.palette.common.white}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Link className={classes.link} component={RouterLink} to={ROOT_APP} underline="none" variant="body2">
          Dashboard
        </Link>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
