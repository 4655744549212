import { Box, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Page from 'src/components/Page';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  content: {
    minHeight: `calc(100vh - ${64}px)`,
    padding: theme.spacing(12, 24),
    backgroundColor: theme.palette.background.neutral,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    '& h1': {
      marginBottom: '1rem',
    },
    '& h2': {
      marginBottom: '1rem',
    },
    '& h3': {
      marginBottom: '1rem',
    },
    '& h4': {
      marginBottom: '1rem',
    },
    '& h5': {
      marginBottom: '1rem',
    },

    '& p': {
      marginBottom: '1rem',
      lineHeight: 1.6,
    },
    '& img': {
      maxHeight: '500px',
      maxWidth: '100%',
    },
    '& ul': {
      marginBottom: '1rem',
      marginLeft: '1rem',
    },
    '& ol': {
      marginTop: '0.5rem',
      marginBottom: '1rem',
      marginLeft: '1rem',
    },
    '& li': {
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& hr': {
      margin: '2rem 0',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(12),
    },
  },
}));

const DocsLayout = () => {
  const classes = useStyles();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <Page title="Docs">
        <Box className={classes.wrapper}>
          <Box className={classes.content}>
            <Outlet />
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default DocsLayout;
