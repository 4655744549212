import { Button, Collapse, ListItem, List, makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  buttonLeaf: {
    padding: '8px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.active': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const NavItem = ({ children, className, depth, href, title, ...rest }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <>
        <ListItem
          style={{
            padding: 0,
          }}
          button
          onClick={handleClick}
        >
          <Button
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact="true"
            style={style}
            to={href}
          >
            {title}
          </Button>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.collapse} in={open} timeout="auto" unmountOnExit>
          <List disablePadding>{children}</List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItem className={clsx(className)} disableGutters {...rest}>
      <Button
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact="true"
        style={style}
        to={href}
      >
        {title}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default NavItem;
