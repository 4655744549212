import { Box, makeStyles } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { PATHS_DOC, ROOT_APP } from 'src/routes/paths';

const LOGO_HEIGHT = 44;

const useStyles = makeStyles(() => ({
  logo: {
    height: LOGO_HEIGHT,
  },
}));

const Logo = ({ disabledLink = false, ...props }) => {
  const classes = useStyles();

  const pathname = useLocation().pathname;

  const isDocs = pathname.includes(PATHS_DOC.ROOT);

  const logo = <Box component="img" src="/static/logo.png" alt="Logo" className={classes.logo} {...props} />;

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={isDocs ? PATHS_DOC.ROOT : ROOT_APP}>{logo}</RouterLink>;
};

export default Logo;
